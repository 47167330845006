angular.module('sq.user', [
  'sq.user.auth',
  'sq.user.auth.events',
  'sq.user.profile.service',
  'sq.user.loginCtrl',
  'sq.user.signupCtrl',
  'sq.user.settings'
])
// NIST 2017 recommendation
// https://pages.nist.gov/800-63-3/sp800-63b.html
.value('minPasswordLength', 8)
.config(function($routeProvider) {
  $routeProvider
  .when('/login', {
    url: '/user/login',
    controller: 'LoginCtrl',
    controllerAs: 'loginCtrl',
    templateUrl: 'user/login/login.tpl.html',
    title: 'Login',
  })
  .when('/signup', {
    url: '/signup',
    controller: 'SignupCtrl',
    controllerAs: 'signupCtrl',
    templateUrl: 'user/signup/signup.tpl.html',
    title: 'Signup',
  })
  .when('/user/settings', {
    url: '/user/settings',
    controller: 'SettingsCtrl',
    controllerAs: 'settingsCtrl',
    templateUrl: 'user/settings/settings.tpl.html',
    title: 'Settings',
  })
  .when('/user/reset', {
    url: '/user/reset',
    controller: 'SettingsCtrl',
    controllerAs: 'settingsCtrl',
    templateUrl: 'user/settings/reset.tpl.html',
    title: 'Settings',
  });
});